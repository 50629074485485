body {
  background-color: #33D085;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.loading {
  color:white;
  font-size: large;
  font-weight: 600;
}

.button {
  border: 0;
  height: 48px;
  width: 200px;
  border-radius: 28px;
  font-size: large;
  font-weight: 600;
  color: #33D085;
}